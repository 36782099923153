
import React from "react";
import { Link } from "react-router-dom";
//

const Sidebar = () => {
    let url = window.location.pathname;
    return (
        <>
            <div className="vertical-menu">
                <div className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title" key="t-menu">Menu</li>

                            <li className={url == '/dashboard' ? 'mm-active' : ''}>
                                <Link to="/dashboard" className="waves-effect">
                                    <span key="t-dashboards">Dashboard</span>
                                </Link>
                            </li>

                            <li> 
                                <a href="#" className="has-arrow waves-effect" aria-expanded="false">
                                    <span key="t-dashboards">VPBX Manager</span>
                                </a>
                                <ul className="sub-menu mm-collapse" aria-expanded="true">
                                    <li className={url == '/working-hours' ? 'mm-active' : ''}><Link to="/working-hours">Working Hours</Link></li>
                                    <li className={url == '/create-department' ? 'mm-active' : ''}><Link to="/create-department">Create Department</Link></li>
                                    <li className={url == '/extensions-details' ? 'mm-active' : ''}><Link to="/extensions-details">Extensions Details</Link></li>
                                    <li className={url == '/update-extensions' ? 'mm-active' : ''}><Link to="/update-extensions">Update Extensions</Link></li>
                                    <li className={url == '/call-forwarding' ? 'mm-active' : ''}><Link to="/call-forwarding">Call Forwarding</Link></li>
                                    <li className={url == '/list-manager' ? 'mm-active' : ''}><Link to="/list-manager">List Manager</Link></li>
                                    <li className={url == '/sound-and' ? 'mm-active' : ''}><Link to="/sound-and-settings">Sound &amp; Settings</Link></li>
                                    <li className={url == '/call-conference' ? 'mm-active' : ''}><Link to="/call-conference">Call Conference</Link></li>
                                    <li className={url == '/ivr-flow' ? 'mm-active' : ''}><Link to="/ivr-flow">IVR Flow</Link></li>
                                    <li className={url == '/event-details' ? 'mm-active' : ''}><Link to="/event-details">Event Details</Link></li>
                                    <li className={url == '/call-routing' ? 'mm-active' : ''}><Link to="/call-routing">Call Routing</Link></li>
                                    <li className={url == '/good-bye-sms' ? 'mm-active' : ''}><Link to="/good-bye-sms">Good Bye SMS</Link></li>
                                </ul>
                            </li>

                            <li>
                                <a href="#" className="has-arrow waves-effect" aria-expanded="false">
                                    <span key="t-dashboards">Reports</span>
                                </a>
                                <ul className="sub-menu mm-collapse" aria-expanded="true">
                                    <li className={url == '/conference-details' ? 'mm-active' : ''}><Link to="/conference-details">Conference Details</Link></li>
                                    <li className={url == '/forwarding-details' ? 'mm-active' : ''}><Link to="/forwarding-details">Forwarding Details</Link></li>
                                    <li className={url == '/account-info' ? 'mm-active' : ''}><Link to="/account-info">Account Info</Link></li>
                                    <li className={url == '/cdr-details' ? 'mm-active' : ''}><Link to="/cdr-details">CDR Details</Link></li>
                                    <li className={url == '/outbound-details' ? 'mm-active' : ''}><Link to="/outbound-details">Outbound Details</Link></li>
                                    <li className={url == '/complaint-report' ? 'mm-active' : ''}><Link to="/complaint-report">Complaint Report</Link></li>
                                </ul>
                            </li>

                            <li>
                                <a href="#" className="has-arrow waves-effect" aria-expanded="false">
                                    <span key="t-dashboards">FAQs</span>
                                </a>
                                <ul className="sub-menu mm-collapse" aria-expanded="true">
                                    <li><Link to="/businesline-vpbx-faqs">Businesline VPBX - FAQs</Link></li>
                                    <li><Link to="/businesline-wab-faqs">Businesline WAB - FAQs</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Sidebar;