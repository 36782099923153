
//
import React, { Suspense } from 'react';

import { Route, Redirect } from "react-router-dom";
import {Provider} from "react-redux";
//
import store from "./store";

import Routes from './Routes.js';

import {GetItem} from './Helper.js';

import Header from "./Common/Header/Header";
import Footer from "./Common/Footer/Footer";
import Sidebar from "./Common/Sidebar/Sidebar";
import Load from './Common/Load';


const protectedRoutes = ['/dashboard', '/logout'];
const unprotectedRoutes = ['/', '/login'];

//
function App({location}) {
	//
	let url = window.location.pathname;
	const isLoggedIn = GetItem('auth_token') || false;
	//;
	if(!isLoggedIn && protectedRoutes.includes(location.pathname)){
		return <Redirect to="/login" />
	}
	//
	if(isLoggedIn && unprotectedRoutes.includes(location.pathname)){
		return <Redirect to="/dashboard" />
	}
	//
    return (
    	<Provider store={store}>
			<Suspense fallback={<Load />}>
				{
					url == '/login' || url == '/' && isLoggedIn == false ? 
						Routes.map((rt, i) => {
							//
							return <Route path={rt.path} component={rt.component} exact={rt.exact} key={i} />
						})
					:
					<>
						<Header />
						<Sidebar />
						{
							Routes.map((rt, i) => {
								//
								return <Route path={rt.path} component={rt.component} exact={rt.exact} key={i} />
							})
						}
						<Footer />
					</>
				}
			</Suspense>
		</Provider>
	);
}

export default App;