//
import { lazy } from 'react';

const Login = lazy(() => import ('./Login/Login.js'));
const Dashboard = lazy(() => import ('./Dashboard/Dashboard.js'));
const WorkingHours = lazy(() => import ('./VpbxManager/WorkingHours.js'));
const CreateDepartment = lazy(() => import ('./VpbxManager/CreateDepartment.js'));
const ExtensionsDetails = lazy(() => import ('./VpbxManager/ExtensionsDetails.js'));
const ExtensionsList = lazy(() => import ('./VpbxManager/ExtensionsList.js'));
const CallForwarding = lazy(() => import ('./VpbxManager/CallForwarding.js'));
const ListManager = lazy(() => import ('./VpbxManager/ListManager.js'));
const SoundAndSettings = lazy(() => import ('./VpbxManager/SoundAndSettings.js'));
const CallConference = lazy(() => import ('./VpbxManager/CallConference.js'));
const IvrFlow = lazy(() => import ('./VpbxManager/IvrFlow.js'));
const IvrFlowMulti = lazy(() => import ('./VpbxManager/IvrFlowMulti.js'));
const EventDetails = lazy(() => import ('./VpbxManager/EventDetails.js'));
const CallRouting = lazy(() => import ('./VpbxManager/CallRouting.js'));
const GoodByeSms = lazy(() => import ('./VpbxManager/GoodByeSms.js'));
const AccountInformation = lazy(() => import ('./Reports/AccountInformation.js'));
const CdrDetails = lazy(() => import ('./Reports/CdrDetails.js'));
const ComplaintDetails = lazy(() => import ('./Reports/ComplaintDetails.js'));
const ConferenceDetail = lazy(() => import ('./Reports/ConferenceDetail.js'));
const ForwardingDetail = lazy(() => import ('./Reports/ForwardingDetail.js'));
const OutboundDetails = lazy(() => import ('./Reports/OutboundDetails.js'));


//
const Routes = [{
        path: '/',
        component: Login,
        exact: true
    },
    {
        path: '/login',
        component: Login,
        exact: true    
    },
    /*AFTER LOGIN*/
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true
    },
    {
        path: '/working-hours',
        component: WorkingHours,
        exact: true
    },
    {
        path: '/create-department',
        component: CreateDepartment,
        exact: true
    },
    {
        path: '/extensions-details',
        component: ExtensionsDetails,
        exact: true
    },
    {
        path: '/update-extensions',
        component: ExtensionsList,
        exact: true
    },
    {
        path: '/call-forwarding',
        component: CallForwarding,
        exact: true
    },
    {
        path: '/list-manager',
        component: ListManager,
        exact: true
    },
    {
        path: '/sound-and-settings',
        component: SoundAndSettings,
        exact: true
    },
    {
        path: '/call-conference',
        component: CallConference,
        exact: true
    },
    {
        path: '/ivr-flow',
        component: IvrFlow,
        exact: true
    },
    {
        path: '/ivr-flow/multi_layer',
        component: IvrFlowMulti,
        exact: true
    },
    {
        path: '/event-details',
        component: EventDetails,
        exact: true
    },
    {
        path: '/call-routing',
        component: CallRouting,
        exact: true
    },
    {
        path: '/good-bye-sms',
        component: GoodByeSms,
        exact: true
    },
    /*Reports*/
    {
        path: '/conference-details',
        component: ConferenceDetail,
        exact: true
    },
    {
        path: '/forwarding-details',
        component: ForwardingDetail,
        exact: true
    },
    {
        path: '/account-info',
        component: AccountInformation,
        exact: true
    },
    {
        path: '/cdr-details',
        component: CdrDetails,
        exact: true
    },
    {
        path: '/outbound-details',
        component: OutboundDetails,
        exact: true
    },
    {
        path: '/complaint-report',
        component: ComplaintDetails,
        exact: true
    },
    /*{
        path : '*',
        component: () => <h1>Route nott found</h1>
    }*/
    
];



export default Routes;