// const Config = {
// 	API_URL: 'http://localhost/MM/jazz/user/',
// 	IMG: 'http://localhost:3000/img/',
// 	UPLOADS: 'http://localhost/MM/jazz_admin/uploads/',
// 	UPLOADS_JAZZ: 'http://localhost/MM/jazz/uploads/'
// };


const Config = {
	API_URL: 'https://pbx.cubixtechnology.co.uk/jazz/user/',
 	IMG: 'https://pbx.cubixtechnology.co.uk/img/',
 	UPLOADS: 'http://pbx.cubixtechnology.co.uk/jazz_admin/uploads/',
	UPLOADS_JAZZ: 'https://pbx.cubixtechnology.co.uk/jazz/uploads/'
};

module.exports =  Config;