import { store } from 'react-notifications-component';
const initState = {
    products: [],
    totalPrice: 0,
}

const CartReducer = (state = initState, action) => {
    let findPro;
    // let index;
    switch(action.type){
        case 'ADD_TO_CART':
        const product = action.payload;

        const check = state.products.find(pr => pr.post_id === product.post_id);
        if(check){
            store.addNotification({
                title: "Already Exist!",
                message: "Product Already in the Cart",
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
            return state;
        } else {
            store.addNotification({
                title: "Product Added!",
                message: "Product Successfully Added into Cart",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
            // const Tprice = state.totalPrice;
            state.totalPrice += product.price; 
            return {
                ...state, products: [...state.products, product] 
            }
        }
        case 'REMOVE':
        findPro = state.products.find(product => product.post_id === action.payload);
        const filtered = state.products.filter(product => product.post_id !== action.payload);
        return {
            ...state,
            products: filtered,
            totalPrice: state.totalPrice - findPro.price
        }
        case 'ALL_REMOVE':
        return {
            ...state,
            products: [],
            totalPrice: 0
        }
        default: 
        return state;
    }

}

export default CartReducer;